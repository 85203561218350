import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { usePageVisibility } from 'react-page-visibility';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { getMetadata } from '../api/api-metadata';
import MaintenancePage from '../components/UI/MaintenancePage';
import { ApiStatusCategories } from '../enum/ApiStatusCategories';
import useHttp from '../hooks/useHttp';
import useStickyState from '../hooks/useStickyState';
import config from './../../src/config.json';
import metadata from './../../src/metadata.json';

const AppContext = React.createContext({
    isLoading: false,
    registerRequest: () => null,
    unregisterRequest: () => null,
    metadata: {},
    isOnline: true,
    isDarkMode: false,
    toggleDarkMode: () => null,
    alerts: [],
    addAlert: () => null,
    listSize: [],
    setListSize: () => null,
    isSlave: false,
    checkMetadata: () => null,
    isOverrideStatus: false,
    toggleOverrideStatus: () => null,
    isStationsFilterSticky: true,
    toggleIsStationsFilterSticky: () => null,
    timestampMetadataRequest: null,
    statusMetadataRequest: ApiStatusCategories.PENDING,
    checkForUpdates: true,
    toggleCheckForUpdates: () => null,
});

const { version: VERSION } = metadata;

const {
    showAlertDuration,
    checkForMaintenanceDuration,
    checkForPlanedMaintenanceDuration,
} = config;

export const AppContextProvider = (props) => {
    const { t } = useTranslation('', { keyPrefix: 'app-context' });
    const [isLoading, setIsLoading] = useState(false);
    const [isOnline, setIsOnline] = useState(true);
    const [isMaintenance, setIsMaintenance] = useState(false);
    const [isMaintenancePlaned, setIsMaintenancePlaned] = useState(false);
    const [listSize, setListSize] = useStickyState(50, 'listSize', true);
    const [requestCounter, setRequestCounter] = useState(0);
    const [isOverrideStatus, setIsOverrideStatus] = useStickyState(
        false,
        'isOverrideStatus'
    );
    const [isStationsFilterSticky, setIsStationsFilterSticky] = useStickyState(
        true,
        'isStationsFilterSticky'
    );
    const [isDarkMode, setIsDarkMode] = useStickyState(false, 'isDarkMode');
    const [checkForUpdates, setCheckForUpdates] = useStickyState(
        true,
        'checkForUpdates'
    );
    const [alerts, setAlerts] = useState([]);
    const [searchParams] = useSearchParams();
    const [isSlave] = useState(searchParams.get('isSlave') ?? false);
    const isPageVisible = usePageVisibility();
    const {
        sendRequest: sendMetadataRequest,
        status: statusMetadataRequest,
        data: metadata,
        timestamp: timestampMetadataRequest,
    } = useHttp(getMetadata, true);

    const removeAlert = useCallback(
        (id) => {
            setAlerts((prevState) =>
                prevState.filter((alert) => alert.id !== id)
            );
        },
        [setAlerts]
    );

    const toggleDarkMode = () => {
        setIsDarkMode((prevState) => !prevState);
    };

    const addAlert = useCallback(
        (alert) => {
            const id = uuidv4();
            setAlerts((prevState) => [...prevState, { ...alert, id: id }]);
            setInterval(() => {
                removeAlert(id);
            }, showAlertDuration);
        },
        [setAlerts, removeAlert]
    );

    const registerRequest = useCallback(() => {
        setRequestCounter((prevState) => prevState + 1);
    }, [setRequestCounter]);

    const unregisterRequest = useCallback(() => {
        setRequestCounter((prevState) => prevState - 1);
    }, [setRequestCounter]);

    const toggleCheckForUpdates = () => {
        setCheckForUpdates((prevState) => !prevState);
    };

    const toggleIsStationsFilterSticky = () => {
        setIsStationsFilterSticky((prevState) => !prevState);
    };

    const loadMetaData = useCallback(() => {
        sendMetadataRequest();
    }, [sendMetadataRequest]);

    const toggleOverrideStatus = useCallback(() => {
        setIsOverrideStatus((prevState) => !prevState);
    }, [setIsOverrideStatus]);

    useEffect(() => {
        const html = document.querySelector('html');
        if (html) {
            html.setAttribute('data-bs-theme', isDarkMode ? 'dark' : 'light');
        }
    }, [isDarkMode]);

    useEffect(() => {
        function handleOnline() {
            setIsOnline(true);
        }

        function handleOffline() {
            setIsOnline(false);
        }

        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };
    }, []);

    useEffect(() => {
        if (statusMetadataRequest === ApiStatusCategories.COMPLETED) {
            setIsMaintenance(metadata.maintenance.enabled);
        }
    }, [setIsMaintenance, metadata, statusMetadataRequest]);

    useEffect(() => {
        if (statusMetadataRequest === ApiStatusCategories.COMPLETED) {
            setIsMaintenancePlaned(metadata.maintenance.planed);
        }
    }, [statusMetadataRequest, metadata, setIsMaintenancePlaned]);

    useEffect(() => {
        setIsLoading(requestCounter > 0);
    }, [requestCounter, setIsLoading]);

    useEffect(() => {
        loadMetaData();
    }, [loadMetaData]);

    useEffect(() => {
        if (statusMetadataRequest === ApiStatusCategories.COMPLETED) {
            if (
                parseInt(
                    `${metadata.version.major}${metadata.version.minor}${metadata.version.revision}${metadata.version.buildnumber}`
                ) >
                parseInt(
                    `${VERSION.major}${VERSION.minor}${VERSION.revision}${VERSION.buildnumber}`
                )
            ) {
                addAlert({
                    title: t('alert.new-version.title'),
                    description: t('alert.new-version.description'),
                    variant: 'dark',
                });
            }
        }
    }, [statusMetadataRequest, metadata, addAlert, t]);

    /**
     * Überprüft, ob eine Wartung geplant oder aktiv ist
     */
    useEffect(() => {
        if (checkForUpdates) {
            const interval = setInterval(
                () => {
                    if (isPageVisible) {
                        loadMetaData();
                    }
                },
                isMaintenancePlaned || isMaintenance
                    ? checkForMaintenanceDuration
                    : checkForPlanedMaintenanceDuration
            );
            return () => {
                clearInterval(interval);
                if (
                    new Date() - new Date(timestampMetadataRequest * 1000) >
                        (isMaintenancePlaned || isMaintenance
                            ? checkForMaintenanceDuration
                            : checkForPlanedMaintenanceDuration) *
                            1.5 &&
                    !isPageVisible
                ) {
                    loadMetaData();
                }
            };
        }
    }, [
        timestampMetadataRequest,
        isMaintenancePlaned,
        checkForUpdates,
        isPageVisible,
        isMaintenance,
        loadMetaData,
    ]);

    const contextValue = {
        isLoading,
        registerRequest,
        unregisterRequest,
        isOnline,
        alerts,
        addAlert,
        isSlave,
        isDarkMode,
        isOverrideStatus,
        toggleOverrideStatus,
        toggleDarkMode,
        metadata,
        checkMetadata: loadMetaData,
        timestampMetadataRequest,
        statusMetadataRequest,
        checkForUpdates,
        toggleCheckForUpdates,
        isStationsFilterSticky,
        toggleIsStationsFilterSticky,
        listSize,
        setListSize,
    };

    return (
        <AppContext.Provider value={contextValue}>
            {isMaintenance ? <MaintenancePage /> : props.children}
        </AppContext.Provider>
    );
};

export default AppContext;
